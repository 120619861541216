.achievement {
  @include breakpoint(desktop) {
    position: relative;
    max-width: 1200px;
    margin: 215px auto 40px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }

  @include breakpoint(tablet-max) {
    margin: 76px auto 0;
  }
  h3 {
    text-transform: uppercase;
    position: relative;

    @include breakpoint(desktop) {
      margin-top: 4px;
      @include font(
        $secondary-font,
        400,
        50px,
        calc(62 / 50),
        $primaryTextColors
      );

      
    animation: fadeInLeft;
    animation-duration: 1.8s;
    }

    @include breakpoint(tablet-max) {
      margin: 0 auto;
      width: 335px;
      z-index: 1;
      @include font(
        $secondary-font,
        400,
        40px,
        calc(56 / 40),
        $primaryTextColors
      );
    }

    span {
      @include breakpoint(desktop) {
        color: $accentTextColors;
      }

      @include breakpoint(tablet-max) {
        @include font(
          $secondary-font,
          400,
          28px,
          calc(52 / 28),
          $accentTextColors
        );
      }
    }
  }
}

.achievement-container {
  @include breakpoint(desktop) {
    display: flex;
  }
}

.blur-effect7 {
  position: relative;
  top: -220px;
  width: 315px;
  height: 219px;
  left: -32px;
  @include breakpoint(desktop) {
    display: none;
  }
  background: rgba(82, 255, 255, 0.7);
  filter: blur(286px);
}

.achievement-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(tablet-max) {
    top: -160px;
    margin: 8px auto 0px;
    gap: 45px;
  }
  @include breakpoint(desktop) {
    margin-bottom: 100px;
    li {
      &:nth-child(1) {
        position: relative;
        left: 24px;
      }

      &:nth-child(2) {
        position: relative;
        left: 38px;
      }

      &:nth-child(3) {
        position: relative;
        left: -10px;
      }
    }

    animation: fadeInRight;
    animation-duration: 1.8s;
  }
  &__value {
    position: absolute;
    @include font(
      $primary-font,
      400,
      115px,
      calc(115 / 115),
      $transparentTextColors
    );
  }

  &__text {
    @include font($primary-font, 400, 20px, calc(24 / 20), $primaryTextColors);
  }

  .text1 {
    @include breakpoint(desktop) {
      width: 459px;
      padding-left: 19px;
      margin-bottom: 66px;
    }

    @include breakpoint(tablet-max) {
      width: 326px;
      padding-left: 18px;
    }
  }

  .value1 {
    @include breakpoint(desktop) {
      top: -11px;
    }
    @include breakpoint(tablet-max) {
      top: -24px;
    }
  }

  .text2 {
    @include breakpoint(desktop) {
      padding-left: 46px;
      width: 538px;
      margin-bottom: 84px;
    }
    @include breakpoint(tablet-max) {
      padding-left: 26px;
      width: 340px;
    }
  }

  .value2 {
    @include breakpoint(desktop) {
      padding-left: 5px;
      top: -24px;
    }
    @include breakpoint(tablet-max) {
      padding-left: -4px;
      top: 116px;
    }
  }

  .text3 {
    @include breakpoint(desktop) {
      padding-left: 40px;
      width: 431px;
    }
    @include breakpoint(tablet-max) {
      padding-left: 31px;
      width: 340px;
    }
  }

  .value3 {
    @include breakpoint(desktop) {
      top: -22px;
    }
    @include breakpoint(tablet-max) {
      top: 256px;
    }
  }
}

.humanity-list-mobile {
  position: relative;
  top: -82px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  text-align: center;
  p {
    @include font(
      $secondary-font,
      400,
      40px,
      calc(24 / 40),
      $transparentTextColors
    );
  }

  @include breakpoint(desktop) {
    display: none;
  }
}
