.about {
  @include breakpoint(desktop) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  @include breakpoint(tablet-max) {
    margin-top: 84px;
  }

  h2 {
    position: relative;

    @include breakpoint(desktop) {
      animation: slideInLeft;
      animation-duration: 1.8s;
    }

    @include font(
      $secondary-font,
      400,
      40px,
      calc(50 / 40),
      $primaryTextColors
    );
  }

  p {
    @include font($primary-font, 400, 20px, calc(24 / 20), $primaryTextColors);
  }
}

// desktop-version
.about-container1 {
  display: flex;
  gap: 72px;
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.about-container2 {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.about-container3 {
  display: flex;
  justify-content: space-between;
}

.about-container4 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  h2 {
    @include font(
      $secondary-font,
      400,
      55px,
      calc(68 / 55),
      $primaryTextColors
    );
    margin-bottom: 10px;
  }
  p {
    position: relative;
    max-width: 411px;
  }
}

.about-container5 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  p {
    position: relative;
  }
}

.about-down-text {
  position: relative;
  margin-top: 25px;
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.about-photo-desktop1 {
  position: relative;
  max-width: 398px;
  height: 539px;
  border-radius: 20px;
}

.about-photo-desktop2 {
  position: relative;
  max-width: 237px;
  height: 309px;
  border-radius: 20px;
}

// mobile-version
.about-title-mobile {
  @include breakpoint(desktop) {
    display: none;
  }
}

.about-photo-mobile {
  position: relative;
  z-index: 1;
  margin: -230px auto 0 auto;
  min-width: 305px;
  border-radius: 20px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.about-text-list-mobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0 20px;
  position: relative;
  z-index: 1;

  @include breakpoint(desktop) {
    display: none;
  }
}

// learn more
.learn-more-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 0px;
  margin-bottom: 0px;

  overflow: hidden;
  position: relative;
  z-index: 2;

  transition: max-height 1s ease, margin-bottom 1s ease;
  @include breakpoint(desktop) {
    display: none;
  }
}

.read-more-cheacker {
  position: absolute;
  z-index: 2;
  opacity: 0;
  @include breakpoint(desktop) {
    display: none;
  }
}

.read-more-button {
  position: absolute;
  z-index: 2;
  right: 15px;
  cursor: pointer;

  @include font($primary-font, 400, 16px, calc(16 / 16), $secondaryTextColors);
  text-decoration-line: underline;
  @include breakpoint(desktop) {
    display: none;
  }
}

.read-more-cheacker:checked ~ .learn-more-text {
  max-height: 520px;
  margin-bottom: 12px;
  @include breakpoint(desktop) {
    display: none;
  }
}

//TODO
.blur-effect6 {
  position: relative;

  width: 235px;
  height: 290px;
  left: 60px;
  top: -110px;

  background: rgb(67, 164, 255);
  filter: blur(265px);

  @include breakpoint(desktop) {
    display: none;
  }
}
