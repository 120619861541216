@import './base/reset';

@import './utils/vars';
@import './utils/mixins';

@import './components/background-effects';
@import './components/header';
@import './components/banner';
@import './components/my-services';
@import './components/about';
@import './components/achievement';
@import './components/ticker-text';
@import './components/companies';
@import './components/my-values';
@import './components/contact';
@import './components/footer';

