.services {
  @include breakpoint(desktop) {
    max-width: 1200px;
    margin: 75px auto;
    padding: 0 15px;
  }

  @include breakpoint(tablet-max) {
    margin-top: 90px;
    height: 354px;
  }
}

.services h2 {
  position: relative;
  z-index: 1;
  text-align: center;

  @include breakpoint(desktop) {
    padding-bottom: 80px;
    @include font(
      $secondary-font,
      400,
      55px,
      calc(68 / 55),
      $primaryTextColors
    );

    animation: fadeInLeft;
    animation-duration: 1.8s;
  }

  @include breakpoint(tablet-max) {
    @include font(
      $secondary-font,
      400,
      40px,
      calc(50 / 40),
      $primaryTextColors
    );
  }

  span {
    color: $accentTextColors;
  }
}

.services-list {
  position: relative;

  display: flex;
  gap: 30px;

  img {
    min-width: 270px;
    height: 234px;
    @include breakpoint(desktop) {
      &:hover {
        transform: scale(1.03);
      }
    }
  }

  @include breakpoint(tablet-max) {
    margin-top: 40px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    li {
      &:nth-child(4) {
        margin-right: 75px;
      }
    }
  }
}

.blur-effect5 {
  position: relative;
  pointer-events: none;
  width: 270px;
  height: 240px;
  left: -10px;
  top: -250px;

  background: #43a5ff;
  filter: blur(265px);

  @include breakpoint(desktop) {
    display: none;
  }
}

// lines
.services-lines {
  @include breakpoint(tablet-max) {
    position: absolute;
    pointer-events: none;
    width: 300px;
    display: flex;
    gap: 10px;
    margin-top: 16px;

    span {
      background-color: $secondaryBgColors;
      width: 65px;
      height: 1px;
      transition: height 0.4s ease;
    }
  }

  @include breakpoint(desktop) {
    display: none;
  }
}
