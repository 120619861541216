html, body {
  scroll-behavior: smooth;
  background-color: #051f30;
  height: 100%;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  fill: currentColor;
}

.list, ul, li, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

section {
  padding: 0 15px;
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.no-scroll {
  overflow: hidden;
}

#container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  #container {
    display: none;
  }
}

.vector-lines1 {
  position: absolute;
  top: -300px;
  right: -425px;
}

.vector-lines2 {
  position: absolute;
  top: 2360px;
  left: -440px;
}

.vector-lines3 {
  position: absolute;
  top: 2936px;
  right: -150px;
}

.vector-lines4 {
  position: absolute;
  top: 4920px;
  left: -120px;
}

.ellipse-desktop-1 {
  filter: blur(206px);
  background: #52ffffc0;
  width: 379px;
  height: 266px;
  position: absolute;
  top: 116px;
  left: -116px;
}

.ellipse-desktop-2 {
  filter: blur(215px);
  background: #43a4ffd0;
  width: 353px;
  height: 365px;
  position: absolute;
  top: 264px;
  left: 985px;
}

.ellipse-desktop-3 {
  filter: blur(200px);
  background: #43a4ffab;
  width: 353px;
  height: 365px;
  position: absolute;
  top: 1018px;
  left: -96px;
}

.ellipse-desktop-4 {
  filter: blur(286px);
  background: #52ffffb9;
  width: 379px;
  height: 266px;
  position: absolute;
  top: 1734px;
  left: 956px;
}

.ellipse-desktop-5 {
  filter: blur(265px);
  background: #43a4ffc0;
  width: 353px;
  height: 365px;
  position: absolute;
  top: 2000px;
  left: 1204px;
}

.ellipse-desktop-6 {
  filter: blur(286px);
  background: #52ffffb3;
  width: 379px;
  height: 266px;
  position: absolute;
  top: 2501px;
  left: 306px;
}

.ellipse-desktop-7 {
  filter: blur(286px);
  background: #52ffffb4;
  width: 385px;
  height: 316px;
  position: absolute;
  top: 3581px;
  left: 726px;
}

.ellipse-desktop-8 {
  filter: blur(265px);
  background: #43a4ffb6;
  width: 353px;
  height: 365px;
  position: absolute;
  top: 4216px;
  left: 1134px;
}

.ellipse-desktop-9 {
  filter: blur(286px);
  background: #52ffffb2;
  width: 379px;
  height: 266px;
  position: absolute;
  top: 4824px;
  left: -24px;
}

.ellipse-desktop-10 {
  filter: blur(265px);
  background: #43a5ff99;
  width: 430px;
  height: 395px;
  position: absolute;
  top: 5324px;
  left: 785px;
}

* {
  transition: all .14s ease-in-out;
}

@media screen and (min-width: 1200px) {
  .header {
    max-width: 1200px;
    margin: 0 auto;
    padding: 21px 15px;
    animation: 1.1s fadeInDown;
  }
}

@media screen and (max-width: 1199.98px) {
  .header {
    padding: 20px 15px 0;
  }
}

@media screen and (min-width: 1200px) {
  .container-header {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.logo {
  width: 137px;
}

@media screen and (max-width: 1199.98px) {
  .logo {
    display: none;
  }
}

.logo-mobile {
  z-index: 2;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .logo-mobile {
    display: none;
  }
}

.nav-menu {
  gap: 50px;
  margin-right: 28px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .nav-menu {
    display: none;
  }
}

.nav-menu__item-menu {
  color: #fff;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.line-nav:after {
  content: "";
  width: 0%;
  height: 2px;
  transition: all .4s ease-in-out;
  display: block;
}

.line-nav:hover:after {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 2px;
  display: block;
}

.list-soc {
  gap: 15px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .list-soc {
    display: none;
  }
}

.list-soc__link {
  cursor: pointer;
  background-color: #d5e9f5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  display: flex;
}

.list-soc__link:hover .soc-icon, .list-soc__link:focus .soc-icon {
  stroke: #929292;
}

.soc-icon {
  fill: #d5e9f5;
  stroke: #031825;
}

#menu-toggle span {
  background: #f9f8fa;
  border-radius: 2px;
  display: block;
}

@media screen and (min-width: 1200px) {
  #menu-toggle span {
    display: none;
  }
}

.mobile-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .mobile-header {
    display: none;
  }
}

.backdrop-menu {
  z-index: 9;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  background-color: #062436;
  width: 100%;
  transition: visibility .35s linear, opacity .35s linear, transform .35s linear;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(-109%);
}

@media screen and (min-width: 1200px) {
  .backdrop-menu {
    display: none;
  }
}

.backdrop-menu.is-open {
  pointer-events: initial;
  visibility: visible;
  opacity: 1;
  width: 100%;
  transform: translateY(0);
}

.mobile-menu {
  height: 900px;
  padding: 20px 15px 0;
}

#menu-toggle {
  z-index: 12;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 15px;
}

#menu-toggle #hamburger {
  width: 100%;
  height: 100%;
  position: absolute;
}

#menu-toggle #hamburger span {
  width: 40px;
  height: 1px;
  position: relative;
}

#menu-toggle #hamburger span:first-child {
  transition-delay: .5s;
}

#menu-toggle #hamburger span:nth-child(2) {
  margin-top: 19px;
  transition-delay: .625s;
}

#menu-toggle #hamburger span:nth-child(3) {
  width: 32px;
  margin-top: 19px;
  margin-left: 8px;
  transition-delay: .75s;
}

#menu-toggle #cross {
  z-index: 18;
  width: 40px;
  height: 40px;
}

#menu-toggle #cross span {
  background-color: #fff;
}

#menu-toggle #cross span:first-child {
  width: 1px;
  height: 0%;
  transition-delay: .25s;
  position: absolute;
  top: -20%;
  left: 20px;
  transform: rotate(180deg);
}

#menu-toggle #cross span:nth-child(2) {
  width: 0%;
  height: 1px;
  transition-delay: .25s;
  position: absolute;
  top: 20px;
  left: -18%;
  transform: rotate(180deg);
}

#menu-toggle.open #hamburger span {
  width: 0%;
}

#menu-toggle.open #hamburger span:first-child {
  transition-delay: 0s;
}

#menu-toggle.open #hamburger span:nth-child(2) {
  transition-delay: .125s;
}

#menu-toggle.open #hamburger span:nth-child(3) {
  transition-delay: .25s;
}

#menu-toggle.open #cross {
  z-index: 18;
}

#menu-toggle.open #cross span:first-child {
  height: 140%;
  transition-delay: .625s;
  transform: rotate(45deg);
}

#menu-toggle.open #cross span:nth-child(2) {
  width: 140%;
  transition-delay: .625s;
  transform: rotate(45deg);
}

.blur-effect1 {
  z-index: 12;
  filter: blur(286px);
  background: #52ffff;
  width: 237px;
  height: 165px;
  position: relative;
  top: 80px;
  left: -28px;
}

@media screen and (min-width: 1200px) {
  .blur-effect1 {
    display: none;
  }
}

.blur-effect2 {
  z-index: 12;
  filter: blur(265px);
  background: #43a5ff;
  width: 256px;
  height: 241px;
  position: relative;
  top: 300px;
  left: 136px;
}

@media screen and (min-width: 1200px) {
  .blur-effect2 {
    display: none;
  }
}

.nav-menu-mobile {
  z-index: 13;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
  top: -300px;
}

.nav-menu-mobile__item-menu {
  color: #fff;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.list-soc-mobile {
  z-index: 13;
  justify-content: center;
  gap: 20px;
  margin: 70px auto 44px;
  display: flex;
  position: relative;
  top: -300px;
}

.list-soc-mobile__link {
  background-color: #d5e9f5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  display: flex;
}

.human-image {
  z-index: 13;
  max-width: 280px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  top: -300px;
}

@media screen and (min-width: 1200px) {
  .banner {
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 15px 75px;
    display: flex;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner {
    height: 1190px;
    margin-top: 80px;
  }
}

@media screen and (min-width: 1200px) {
  .banner-desktop {
    align-items: center;
    display: flex;
  }

  .banner-info {
    max-width: 594px;
    margin-right: 34px;
    padding: 38px 0 0;
    animation: 1.4s slideInLeft;
  }
}

.banner-title {
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .banner-title {
    color: #f9f8fa;
    width: 100%;
    font-family: Libre Baskerville, sans-serif;
    font-size: 125px;
    font-weight: 400;
    line-height: 1.24;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner-title {
    color: #f9f8fa;
    min-width: 40px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 1.24286;
  }
}

.banner-text {
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .banner-text {
    color: #f9f8fa;
    max-width: 448px;
    margin-top: 30px;
    font-family: Josefin Sans, sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 1;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner-text {
    color: #f9f8fa;
    margin-top: 20px;
    font-family: Josefin Sans, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
  }
}

.banner-text__accent {
  color: #fcfeaa;
}

.bunner-btn-border1 {
  background: linear-gradient(168deg, #fff0 25%, #ffffffa2 64%, #fff 89%);
  border-radius: 70px;
  width: 171px;
  margin-top: 40px;
}

@media screen and (min-width: 1200px) {
  .bunner-btn-border1 {
    height: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .bunner-btn-border1 {
    height: 35px;
  }
}

.bunner-btn-border2 {
  z-index: 2;
  background-color: #062436;
  border-radius: 70px;
  width: 170px;
  margin-top: 1px;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .bunner-btn-border2 {
    height: 38px;
  }
}

@media screen and (max-width: 1199.98px) {
  .bunner-btn-border2 {
    height: 33px;
  }
}

.bunner-button {
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  background: radial-gradient(681.23% 138.89% at 50% 48.89%, #d5e9f533 8.12%, #fff 100%);
  border: none;
  border-radius: 70px;
  width: 170px;
  height: 35px;
  margin-top: -1px;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .bunner-button {
    height: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .bunner-button {
    height: 35px;
  }
}

.bunner-button:hover {
  background: radial-gradient(735.73% 150% at 50% 120%, #d5e9f533 8.12%, #fff 100%);
}

.banner-human-image {
  padding-right: 40px;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .banner-human-image {
    display: none;
  }
}

.banner-human-image img {
  max-width: 484px;
}

.banner-human-image-mobile {
  z-index: 1;
  width: 310px;
  margin: 50px auto;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .banner-human-image-mobile {
    display: none;
  }
}

.blur-effect3 {
  filter: blur(286px);
  background: #52ffff;
  width: 227px;
  height: 165px;
  position: relative;
  top: -1160px;
  left: -95px;
}

@media screen and (min-width: 1200px) {
  .blur-effect3 {
    display: none;
  }
}

.blur-effect4 {
  filter: blur(265px);
  background: #43a5ff;
  width: 256px;
  height: 241px;
  position: relative;
  top: -970px;
  left: 50px;
}

@media screen and (min-width: 1200px) {
  .blur-effect4 {
    display: none;
  }
}

.banner-list {
  display: flex;
}

@media screen and (min-width: 1200px) {
  .banner-list {
    justify-content: space-evenly;
    align-items: center;
    gap: 60px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner-list {
    text-align: center;
    flex-direction: column;
    gap: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .banner-list li:first-child {
    width: 257px;
    height: 170px;
  }

  .banner-list li:nth-child(2) {
    width: 272px;
    height: 170px;
  }

  .banner-list li:nth-child(3) {
    width: 305px;
    height: 150px;
    margin-bottom: 20px;
  }
}

.banner-item {
  color: #f9f8fa;
}

@media screen and (min-width: 1200px) {
  .banner-item__value {
    text-align: center;
    color: inherit;
    height: 115px;
    margin-bottom: 15px;
    font-family: Josefin Sans, sans-serif;
    font-size: 115px;
    font-weight: 400;
    line-height: 1;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner-item__value {
    color: inherit;
    height: 70px;
    margin-bottom: 10px;
    font-family: Josefin Sans, sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 1;
  }
}

@media screen and (min-width: 1200px) {
  .banner-item__text {
    text-align: center;
    color: inherit;
    font-family: Josefin Sans, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
  }
}

@media screen and (max-width: 1199.98px) {
  .banner-item__text {
    color: inherit;
    font-family: Josefin Sans, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1200px) {
  .services {
    max-width: 1200px;
    margin: 75px auto;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1199.98px) {
  .services {
    height: 354px;
    margin-top: 90px;
  }
}

.services h2 {
  z-index: 1;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .services h2 {
    color: #f9f8fa;
    padding-bottom: 80px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 55px;
    font-weight: 400;
    line-height: 1.23636;
    animation: 1.8s fadeInLeft;
  }
}

@media screen and (max-width: 1199.98px) {
  .services h2 {
    color: #f9f8fa;
    font-family: Libre Baskerville, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.25;
  }
}

.services h2 span {
  color: #fcfeaa;
}

.services-list {
  gap: 30px;
  display: flex;
  position: relative;
}

.services-list img {
  min-width: 270px;
  height: 234px;
}

@media screen and (min-width: 1200px) {
  .services-list img:hover {
    transform: scale(1.03);
  }
}

@media screen and (max-width: 1199.98px) {
  .services-list {
    margin-top: 40px;
    overflow-x: auto;
  }

  .services-list::-webkit-scrollbar {
    width: 0;
  }

  .services-list li:nth-child(4) {
    margin-right: 75px;
  }
}

.blur-effect5 {
  pointer-events: none;
  filter: blur(265px);
  background: #43a5ff;
  width: 270px;
  height: 240px;
  position: relative;
  top: -250px;
  left: -10px;
}

@media screen and (min-width: 1200px) {
  .blur-effect5 {
    display: none;
  }
}

@media screen and (max-width: 1199.98px) {
  .services-lines {
    pointer-events: none;
    gap: 10px;
    width: 300px;
    margin-top: 16px;
    display: flex;
    position: absolute;
  }

  .services-lines span {
    background-color: #d5e9f5;
    width: 65px;
    height: 1px;
    transition: height .4s;
  }
}

@media screen and (min-width: 1200px) {
  .services-lines {
    display: none;
  }

  .about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1199.98px) {
  .about {
    margin-top: 84px;
  }
}

.about h2 {
  color: #f9f8fa;
  font-family: Libre Baskerville, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .about h2 {
    animation: 1.8s slideInLeft;
  }
}

.about p {
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.about-container1 {
  gap: 72px;
  display: flex;
}

@media screen and (max-width: 1199.98px) {
  .about-container1 {
    display: none;
  }
}

.about-container2 {
  flex-direction: column;
  gap: 27px;
  display: flex;
}

.about-container3 {
  justify-content: space-between;
  display: flex;
}

.about-container4 {
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.about-container4 h2 {
  color: #f9f8fa;
  margin-bottom: 10px;
  font-family: Libre Baskerville, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 1.23636;
}

.about-container4 p {
  max-width: 411px;
  position: relative;
}

.about-container5 {
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.about-container5 p {
  position: relative;
}

.about-down-text {
  margin-top: 25px;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .about-down-text {
    display: none;
  }
}

.about-photo-desktop1 {
  border-radius: 20px;
  max-width: 398px;
  height: 539px;
  position: relative;
}

.about-photo-desktop2 {
  border-radius: 20px;
  max-width: 237px;
  height: 309px;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .about-title-mobile {
    display: none;
  }
}

.about-photo-mobile {
  z-index: 1;
  border-radius: 20px;
  min-width: 305px;
  margin: -230px auto 0;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .about-photo-mobile {
    display: none;
  }
}

.about-text-list-mobile {
  z-index: 1;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0 20px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .about-text-list-mobile {
    display: none;
  }
}

.learn-more-text {
  z-index: 2;
  flex-direction: column;
  gap: 20px;
  max-height: 0;
  margin-bottom: 0;
  transition: max-height 1s, margin-bottom 1s;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1200px) {
  .learn-more-text {
    display: none;
  }
}

.read-more-cheacker {
  z-index: 2;
  opacity: 0;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .read-more-cheacker {
    display: none;
  }
}

.read-more-button {
  z-index: 2;
  cursor: pointer;
  color: #fff;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-decoration-line: underline;
  position: absolute;
  right: 15px;
}

@media screen and (min-width: 1200px) {
  .read-more-button {
    display: none;
  }
}

.read-more-cheacker:checked ~ .learn-more-text {
  max-height: 520px;
  margin-bottom: 12px;
}

@media screen and (min-width: 1200px) {
  .read-more-cheacker:checked ~ .learn-more-text {
    display: none;
  }
}

.blur-effect6 {
  filter: blur(265px);
  background: #43a4ff;
  width: 235px;
  height: 290px;
  position: relative;
  top: -110px;
  left: 60px;
}

@media screen and (min-width: 1200px) {
  .blur-effect6 {
    display: none;
  }

  .achievement {
    flex-direction: column;
    max-width: 1200px;
    margin: 215px auto 40px;
    padding: 0 15px;
    display: flex;
    position: relative;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement {
    margin: 76px auto 0;
  }
}

.achievement h3 {
  text-transform: uppercase;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .achievement h3 {
    color: #f9f8fa;
    margin-top: 4px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.24;
    animation: 1.8s fadeInLeft;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement h3 {
    z-index: 1;
    color: #f9f8fa;
    width: 335px;
    margin: 0 auto;
    font-family: Libre Baskerville, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.4;
  }
}

@media screen and (min-width: 1200px) {
  .achievement h3 span {
    color: #fcfeaa;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement h3 span {
    color: #fcfeaa;
    font-family: Libre Baskerville, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.85714;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-container {
    display: flex;
  }
}

.blur-effect7 {
  filter: blur(286px);
  background: #52ffffb3;
  width: 315px;
  height: 219px;
  position: relative;
  top: -220px;
  left: -32px;
}

@media screen and (min-width: 1200px) {
  .blur-effect7 {
    display: none;
  }
}

.achievement-list {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .achievement-list {
    gap: 45px;
    margin: 8px auto 0;
    top: -160px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list {
    margin-bottom: 100px;
    animation: 1.8s fadeInRight;
  }

  .achievement-list li:first-child {
    position: relative;
    left: 24px;
  }

  .achievement-list li:nth-child(2) {
    position: relative;
    left: 38px;
  }

  .achievement-list li:nth-child(3) {
    position: relative;
    left: -10px;
  }
}

.achievement-list__value {
  color: #d5e9f533;
  font-family: Josefin Sans, sans-serif;
  font-size: 115px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

.achievement-list__text {
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (min-width: 1200px) {
  .achievement-list .text1 {
    width: 459px;
    margin-bottom: 66px;
    padding-left: 19px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .text1 {
    width: 326px;
    padding-left: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list .value1 {
    top: -11px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .value1 {
    top: -24px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list .text2 {
    width: 538px;
    margin-bottom: 84px;
    padding-left: 46px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .text2 {
    width: 340px;
    padding-left: 26px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list .value2 {
    padding-left: 5px;
    top: -24px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .value2 {
    padding-left: -4px;
    top: 116px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list .text3 {
    width: 431px;
    padding-left: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .text3 {
    width: 340px;
    padding-left: 31px;
  }
}

@media screen and (min-width: 1200px) {
  .achievement-list .value3 {
    top: -22px;
  }
}

@media screen and (max-width: 1199.98px) {
  .achievement-list .value3 {
    top: 256px;
  }
}

.humanity-list-mobile {
  text-align: center;
  flex-direction: column;
  gap: 42px;
  display: flex;
  position: relative;
  top: -82px;
}

.humanity-list-mobile p {
  color: #d5e9f533;
  font-family: Libre Baskerville, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: .6;
}

@media screen and (min-width: 1200px) {
  .humanity-list-mobile {
    display: none;
  }
}

.ticker-container {
  max-width: 1440px;
  margin: 0 auto 45px;
  overflow: hidden;
}

@media screen and (max-width: 1199.98px) {
  .ticker-container {
    display: none;
  }
}

.ticker-container div {
  width: 200%;
  animation: 12s linear infinite marquee-infinite;
  display: flex;
}

.ticker-container span {
  justify-content: space-around;
  gap: 50px;
  width: 100%;
  display: flex;
}

.ticker-container p {
  color: #d5e9f533;
  width: 20%;
  height: 100%;
  font-family: Libre Baskerville, sans-serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 1.23636;
}

.ticker-text {
  gap: 50px;
}

@keyframes marquee-infinite {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-101%);
  }
}

.companies {
  background-color: #d5e9f5;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .companies {
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 15px;
  }
}

@media screen and (min-width: 1200px) {
  .companies h2 {
    color: #062436;
    font-family: Libre Baskerville, sans-serif;
    font-size: 55px;
    font-weight: 400;
    line-height: 1.23636;
  }
}

@media screen and (max-width: 1199.98px) {
  .companies h2 {
    text-align: center;
    color: #062436;
    font-family: Libre Baskerville, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media screen and (min-width: 1200px) {
  .companies p {
    color: #062436;
    max-width: 632px;
    font-family: Josefin Sans, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33333;
  }
}

@media screen and (max-width: 1199.98px) {
  .companies p {
    color: #062436;
    margin-top: 30px;
    font-family: Josefin Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71429;
  }
}

@media screen and (min-width: 1200px) {
  .companies-container {
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 97px 15px 84px;
    display: flex;
  }

  .companies-container div {
    flex-direction: column-reverse;
    gap: 44px;
    display: flex;
  }

  .companies-list {
    align-items: center;
    gap: 50px;
    display: flex;
  }

  .companies-list li:first-child {
    width: 149px;
  }

  .companies-list li:nth-child(2) {
    width: 280px;
  }

  .companies-list li:nth-child(3) {
    width: 237px;
  }
}

@media screen and (max-width: 1199.98px) {
  .companies-list {
    display: none;
  }
}

.companies-list-mobile {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  display: flex;
}

.companies-list-mobile li:first-child {
  width: 240px;
}

.companies-list-mobile li:nth-child(2) {
  width: 237px;
}

.companies-list-mobile li:nth-child(3) {
  width: 122px;
}

.companies-list-mobile li:nth-child(4) {
  width: 150px;
}

.companies-list-mobile li:nth-child(5) {
  width: 240px;
}

@media screen and (min-width: 1200px) {
  .companies-list-mobile {
    display: none;
  }

  .my-values {
    max-width: 1200px;
    margin: 0 auto;
    padding: 150px 15px;
    position: relative;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 1199.98px) {
  .my-values {
    height: 1650px;
    padding: 80px 15px 20px;
  }
}

.my-values h2 {
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .my-values h2 {
    color: #f9f8fa;
    margin-bottom: 80px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.24;
    animation: 1.8s slideInLeft;
  }
}

@media screen and (max-width: 1199.98px) {
  .my-values h2 {
    color: #f9f8fa;
    font-family: Libre Baskerville, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.25;
  }
}

.my-values h2 span {
  color: #fcfeaa;
}

@media screen and (max-width: 1199.98px) {
  .my-values img {
    z-index: 1;
    width: 100%;
    margin: 0 auto 80px;
    position: relative;
  }
}

.my-mission-desktop:hover {
  transform: scale(1.015);
}

@media screen and (max-width: 1199.98px) {
  .my-mission-desktop {
    display: none;
  }
}

.my-mission-container {
  align-items: center;
  display: flex;
  position: relative;
}

.my-mission {
  min-width: 305px;
  top: -260px;
}

@media screen and (min-width: 1200px) {
  .my-mission {
    display: none;
  }
}

.my-values-list {
  z-index: 1;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .my-values-list {
    justify-content: center;
    gap: 120px;
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 1199.98px) {
  .my-values-list {
    flex-direction: column;
    margin: 0 auto;
    padding-bottom: 60px;
    top: -260px;
  }
}

@media screen and (min-width: 1200px) {
  .my-values-list img {
    width: 70px;
    margin: 0 auto 30px;
  }
}

@media screen and (max-width: 1199.98px) {
  .my-values-list img {
    width: 50px;
    margin: 40px auto 20px;
  }
}

.my-values-list img:hover {
  transform: scale(1.02);
}

.my-values-list h3 {
  text-align: center;
  color: #f9f8fa;
  margin-bottom: 20px;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.my-values-list p {
  text-align: center;
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
}

.my-values-list .text1 {
  width: 265px;
  margin: 0 auto;
}

.my-values-list .text2 {
  width: 296px;
  margin: 0 auto;
}

.my-values-list .text3 {
  width: 265px;
  margin: 0 auto;
}

.my-values-text-desktop {
  padding: 170px 0 20px;
}

.my-values-text-desktop ul {
  flex-direction: column;
  display: flex;
}

.my-values-text-desktop ul li {
  animation-duration: 1.6s;
  position: relative;
}

.my-values-text-desktop ul li:nth-child(2) {
  margin-left: auto;
}

.my-values-text-desktop ul li:nth-child(3) {
  margin-left: auto;
  margin-right: 150px;
}

.my-values-text-desktop ul li:nth-child(5) {
  margin-left: auto;
  margin-right: 134px;
}

.my-values-text-desktop ul li:nth-child(6) {
  margin-left: 80px;
}

.my-values-text-desktop ul li:nth-child(7) {
  margin-left: auto;
}

.my-values-text-desktop p {
  text-transform: uppercase;
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 54px;
  font-weight: 400;
  line-height: 1.64815;
}

@media screen and (max-width: 1199.98px) {
  .my-values-text-desktop {
    display: none;
  }
}

.my-values-text {
  text-transform: uppercase;
  text-align: center;
  color: #f9f8fa;
  max-width: 345px;
  margin: 0 auto 20px;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 2;
  position: relative;
  top: -546px;
}

@media screen and (min-width: 1200px) {
  .my-values-text {
    display: none;
  }
}

.my-values-text-author {
  justify-content: flex-end;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .my-values-text-author {
    color: #f9f8fa;
    font-family: Libre Baskerville, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    animation: 1.4s fadeInRight;
  }
}

@media screen and (max-width: 1199.98px) {
  .my-values-text-author {
    text-align: center;
    color: #f9f8fa;
    font-family: Libre Baskerville, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    top: -546px;
  }
}

.blur-effect8 {
  filter: blur(286px);
  background: #52ffff;
  width: 316px;
  height: 252px;
  position: relative;
  top: 30px;
  left: 0;
}

@media screen and (min-width: 1200px) {
  .blur-effect8 {
    display: none;
  }
}

.blur-effect9 {
  filter: blur(265px);
  background: #43a4ff;
  width: 219px;
  height: 283px;
  position: relative;
  top: -404px;
  left: 100px;
}

@media screen and (min-width: 1200px) {
  .blur-effect9 {
    display: none;
  }

  .contact {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px 150px;
    position: relative;
  }
}

@media screen and (max-width: 1199.98px) {
  .contact {
    height: 920px;
    margin-bottom: 80px;
  }
}

.contact h2 {
  z-index: 1;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .contact h2 {
    color: #e9e9e9;
    margin-bottom: 40px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media screen and (min-width: 1200px) {
  .contact h2 {
    color: #e9e9e9;
    margin-bottom: 80px;
    font-family: Libre Baskerville, sans-serif;
    font-size: 55px;
    font-weight: 400;
    line-height: 1.23636;
    animation: 1.8s fadeInLeft;
  }
}

.contact__text {
  z-index: 1;
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .contact__text {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1199.98px) {
  .contact__text {
    margin: 0 auto 30px;
  }
}

.contact-desktop-img {
  z-index: 1;
  border-radius: 20px;
  width: 370px;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .contact-desktop-img {
    display: none;
  }
}

.contact-mobile-img {
  z-index: 1;
  border-radius: 20px;
  min-width: 305px;
  margin: 60px auto 0;
  position: relative;
  top: -280px;
}

@media screen and (min-width: 1200px) {
  .contact-mobile-img {
    display: none;
  }

  .contact-container {
    flex-direction: row-reverse;
    justify-content: space-between;
    display: flex;
  }

  .form-container {
    max-width: 700px;
    animation: 1.4s fadeInUp;
  }
}

.blur-effect10 {
  filter: blur(286px);
  background: #52ffffe3;
  width: 270px;
  height: 275px;
  position: relative;
  top: -12px;
  left: 55px;
}

@media screen and (min-width: 1200px) {
  .blur-effect10 {
    display: none;
  }
}

.contact-form {
  z-index: 1;
  flex-direction: column;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .contact-form {
    gap: 30px;
  }
}

@media screen and (max-width: 1199.98px) {
  .contact-form {
    gap: 15px;
    top: -280px;
  }
}

.contact-form textarea {
  resize: none;
  border-radius: 20px;
  height: 80px;
  padding: 15px 20px;
}

@media screen and (min-width: 1200px) {
  .contact-form textarea {
    border-radius: 30px;
    height: 100px;
    padding: 20px 25px;
  }
}

.contact-form input {
  border-radius: 70px;
  padding: 13px 20px;
}

@media screen and (min-width: 1200px) {
  .contact-form input {
    height: 60px;
    padding: 21px 25px;
  }
}

.form-input {
  background-color: inherit;
  color: #fff;
  border: 1px solid #f9f8fa;
  width: 100%;
}

.form-input::placeholder {
  color: #acacac;
  font-family: Josefin Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

@media screen and (min-width: 1200px) {
  .form-input::placeholder {
    color: #acacac;
    font-family: Josefin Sans, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
  }
}

.form-input:focus {
  outline-offset: 0;
  border: 1px solid #2f546b;
  outline: 0;
}

.form-input:focus::placeholder {
  color: #2f546b;
}

.contact-btn-border1 {
  background: linear-gradient(168deg, #fff0 25%, #ffffffa2 64%, #fff 89%);
  border-radius: 70px;
  width: 171px;
  height: 35px;
}

@media screen and (min-width: 1200px) {
  .contact-btn-border1 {
    height: 45px;
  }
}

.contact-btn-border2 {
  z-index: 2;
  background-color: #062436;
  border-radius: 70px;
  width: 170px;
  height: 33px;
  margin-top: 1px;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .contact-btn-border2 {
    height: 43px;
  }
}

.contact-button {
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  background: radial-gradient(163.09% 86.67% at 50% 48.89%, #d5e9f533 8.12%, #fff 100%);
  border: none;
  border-radius: 70px;
  width: 170px;
  height: 35px;
  margin-top: -1px;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .contact-button {
    height: 45px;
  }
}

.contact-button:focus {
  background: radial-gradient(735.73% 150% at 50% 120%, #d5e9f533 8.12%, #fff 100%);
}

.communication-list {
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  .communication-list {
    top: -280px;
  }
}

.communication-list a {
  align-items: center;
  gap: 20px;
  display: flex;
}

.communication-list a svg {
  stroke: #f9f8fa;
}

.communication-list a:hover p, .communication-list a:focus p {
  color: #b8b4b4;
}

.communication-list a:hover svg, .communication-list a:focus svg {
  stroke: #b8b4b4;
}

.communication-list p {
  color: #f9f8fa;
  font-family: Josefin Sans, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
}

.communication-list .contact-icon-mail {
  margin-bottom: 4px;
}

@media screen and (min-width: 1200px) {
  .communication-list {
    animation: 1.4s fadeInUp;
  }
}

.validation-message, .email-validation-message, .message-validationMessage {
  z-index: 1;
  position: absolute;
}

@media screen and (min-width: 1200px) {
  .validation-message, .email-validation-message, .message-validationMessage {
    color: #ff1f1f;
    margin-top: 4px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
  }
}

@media screen and (max-width: 1199.98px) {
  .validation-message, .email-validation-message, .message-validationMessage {
    color: #ff1f1f;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
  }
}

footer {
  background-color: #031825;
  padding: 50px 0 52px;
  position: relative;
}

@media screen and (max-width: 1199.98px) {
  footer {
    padding: 40px 15px 10px;
  }
}

footer img {
  width: 137px;
}

@media screen and (min-width: 1200px) {
  .footer-container-desktop {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
  }
}

.footer-desktop-logo {
  width: 137px;
  margin-right: 40px;
}

@media screen and (max-width: 1199.98px) {
  .footer-desktop-logo {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .footer-mobile-logo {
    display: none;
  }
}

.footer-container {
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 1200px) {
  .footer-container {
    align-items: center;
  }
}

@media screen and (max-width: 1199.98px) {
  .footer-container {
    margin-top: 30px;
  }
}

.footer-menu {
  display: flex;
}

@media screen and (min-width: 1200px) {
  .footer-menu {
    gap: 50px;
  }
}

@media screen and (max-width: 1199.98px) {
  .footer-menu {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 177px;
    margin-top: 11px;
  }

  .footer-menu li:nth-child(n+3) {
    margin-top: 20px;
  }
}

.footer-menu p {
  color: #fff;
  font-family: Josefin Sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.list-soc-footer {
  justify-content: center;
  gap: 20px;
  display: flex;
}

.list-soc-footer__link {
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  padding-top: 1px;
  display: flex;
}

.list-soc-footer__link:hover .soc-icon-footer, .list-soc-footer__link:focus .soc-icon-footer {
  stroke: #929292;
}

.list-soc-footer .accent {
  background-color: #d5e9f5;
}

.soc-icon-footer {
  fill: #fff;
  stroke: #031825;
}

.text-copyright {
  color: #b8b8b8;
  margin-top: 40px;
  font-family: Josefin Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}

@media screen and (max-width: 1199.98px) {
  .text-copyright {
    color: #b8b8b8;
    font-family: Josefin Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
  }

  .item-arrow {
    display: none;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px)rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px)rotateX(0);
}

/*# sourceMappingURL=index.c5cfc287.css.map */
