.banner {
  @include breakpoint(desktop) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 15px 75px;
    display: flex;
    flex-direction: column;
  }
  @include breakpoint(tablet-max) {
    margin-top: 80px;
    height: 1190px;
  }
}

.banner-desktop {
  @include breakpoint(desktop) {
    display: flex;
    align-items: center;
  }
}

.banner-info {
  @include breakpoint(desktop) {
    padding: 38px 0 0;
    margin-right: 34px;
    max-width: 594px;

    animation: slideInLeft;
    animation-duration: 1.4s;
  }
}

.banner-title {
  position: relative;
  z-index: 1;
  @include breakpoint(desktop) {
    width: 100%;
    @include font(
      $secondary-font,
      400,
      125px,
      calc(155 / 125),
      $primaryTextColors
    );
  }
  @include breakpoint(tablet-max) {
    min-width: 40px;
    @include font(
      $secondary-font,
      400,
      70px,
      calc(87 / 70),
      $primaryTextColors
    );
  }
}

.banner-text {
  position: relative;
  z-index: 1;

  @include breakpoint(desktop) {
    max-width: 448px;
    margin-top: 30px;
    @include font($primary-font, 400, 32px, calc(32 / 32), $primaryTextColors);
  }

  @include breakpoint(tablet-max) {
    margin-top: 20px;
    @include font($primary-font, 400, 24px, calc(24 / 24), $primaryTextColors);
  }

  &__accent {
    color: $accentTextColors;
  }
}

.bunner-btn-border1 {
  margin-top: 40px;
  width: 171px;
  border-radius: 70px;

  @include breakpoint(desktop) {
    height: 40px;
  }

  @include breakpoint(tablet-max) {
    height: 35px;
  }
  background: linear-gradient(
    168deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.6362920168067228) 64%,
    rgba(255, 255, 255, 1) 89%
  );
}

.bunner-btn-border2 {
  position: absolute;
  z-index: 2;
  margin-top: 1px;
  width: 170px;

  @include breakpoint(desktop) {
    height: 38px;
  }

  @include breakpoint(tablet-max) {
    height: 33px;
  }
  border-radius: 70px;
  background-color: $primaryBgColors;
}

.bunner-button {
  margin-top: -1px;
  position: absolute;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  width: 170px;
  height: 35px;
  @include breakpoint(desktop) {
    height: 40px;
  }

  @include breakpoint(tablet-max) {
    height: 35px;
  }

  background: radial-gradient(
    681.23% 138.89% at 50% 48.89%,
    rgba(213, 233, 245, 0.2) 8.12%,
    #ffffff 100%
  );

  border: none;
  border-radius: 70px;
  @include font($primary-font, 400, 16px, calc(16 / 16), $secondaryTextColors);
}

.bunner-button:hover {
  background: radial-gradient(
    735.73% 150% at 50% 120%,
    rgba(213, 233, 245, 0.2) 8.12%,
    #ffffff 100%
  );
}

.banner-human-image {
  position: relative;
  padding-right: 40px;
  @include breakpoint(tablet-max) {
    display: none;
  }

  img {
    max-width: 484px;
  }
}

//* ------------------- MOBILE -------------------

.banner-human-image-mobile {
  margin: 50px auto;
  width: 310px;
  position: relative;
  z-index: 1;
  @include breakpoint(desktop) {
    display: none;
  }
}

// TODO Блюр (проблема с отображением на iphone 8)
.blur-effect3 {
  position: relative;
  width: 227px;
  height: 165px;
  left: -95px;
  top: -1160px;

  background: #52ffff;
  filter: blur(286px);

  @include breakpoint(desktop) {
    display: none;
  }
}

.blur-effect4 {
  position: relative;
  width: 256px;
  height: 241px;
  left: 50px;
  top: -970px;

  background: #43a5ff;
  filter: blur(265px);

  @include breakpoint(desktop) {
    display: none;
  }
}

// experience list
.banner-list {
  display: flex;
  @include breakpoint(desktop) {
    margin-top: 100px;
    align-items: center;
    justify-content: space-evenly;
    gap: 60px;
  }
  @include breakpoint(tablet-max) {
    text-align: center;
    flex-direction: column;
    gap: 40px;
  }
  li {
    @include breakpoint(desktop) {
      &:nth-child(1) {
        height: 170px;
        width: 257px;
      }
      &:nth-child(2) {
        height: 170px;
        width: 272px;
      }
      &:nth-child(3) {
        height: 150px;
        width: 305px;
        margin-bottom: 20px;
      }
    }
  }
}

.banner-item {
  color: $primaryTextColors;

  &__value {
    @include breakpoint(desktop) {
      height: 115px;
      margin-bottom: 15px;
      text-align: center;
      @include font($primary-font, 400, 115px, calc(115 / 115));
    }

    @include breakpoint(tablet-max) {
      height: 70px;
      margin-bottom: 10px;

      @include font($primary-font, 400, 70px, calc(70 / 70));
    }
  }

  &__text {
    @include breakpoint(desktop) {
      text-align: center;
      @include font($primary-font, 400, 20px, calc(20 / 20));
    }

    @include breakpoint(tablet-max) {
      @include font($primary-font, 400, 20px, calc(24 / 20));
    }
  }
}
