footer {
  position: relative;
  padding: 50px 0 52px;

  background-color: $footerBgColors;

  @include breakpoint(tablet-max) {
    padding: 40px 15px 10px;
  }

  img {
    width: 137px;
  }
}

.footer-container-desktop {
  @include breakpoint(desktop) {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.footer-desktop-logo {
  width: 137px;
  margin-right: 40px;
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.footer-mobile-logo {
  @include breakpoint(desktop) {
    display: none;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  @include breakpoint(desktop) {
    align-items: center;
  }
  @include breakpoint(tablet-max) {
    margin-top: 30px;
  }
}

.footer-menu {
  display: flex;

  @include breakpoint(desktop) {
    gap: 50px;
  }

  @include breakpoint(tablet-max) {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 11px;
    width: 177px;

    li {
      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    }
  }
  p {
    @include font(
      $primary-font,
      400,
      16px,
      calc(16 / 16),
      $secondaryTextColors
    );
  }
}

.list-soc-footer {
  display: flex;
  justify-content: center;
  gap: 20px;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;

    width: 38px;
    height: 38px;

    border-radius: 50%;
    background-color: #fff;
    &:hover .soc-icon-footer,
    &:focus .soc-icon-footer{
      stroke: #929292;
    }
  }

  .accent {
    background-color: #D5E9F5;
  }
}

.soc-icon-footer {
  fill: #fff;
  stroke: #031825;
}

.text-copyright {
  margin-top: 40px;
  @include font($primary-font, 400, 14px, calc(14 / 14), $copyrightTextColors);
  @include breakpoint(tablet-max) {
    @include font(
      $primary-font,
      400,
      12px,
      calc(12 / 12),
      $copyrightTextColors
    );
  }
}

.item-arrow {
  @include breakpoint(tablet-max) {
    display: none;
  }
}

// .soc-icon-footer-arrow {

// }