.contact {
  @include breakpoint(desktop) {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px 150px;
  }

  @include breakpoint(tablet-max) {
    margin-bottom: 80px;
    height: 920px;
  }

  h2 {
    position: relative;
    z-index: 1;
    text-align: center;

    @include breakpoint(tablet-max) {
      margin-bottom: 40px;
      @include font(
        $secondary-font,
        400,
        40px,
        calc(50 / 40),
        $contactTextColors
      );
    }

    @include breakpoint(desktop) {
      margin-bottom: 80px;
      @include font(
        $secondary-font,
        400,
        55px,
        calc(68 / 55),
        $contactTextColors
      );

      animation: fadeInLeft;
      animation-duration: 1.8s;
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    @include font($primary-font, 400, 20px, calc(20 / 20), $primaryTextColors);
    @include breakpoint(desktop) {
      margin-bottom: 40px;
    }

    @include breakpoint(tablet-max) {
      margin: 0 auto 30px;
    }
  }
}

.contact-desktop-img {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  width: 370px;

  @include breakpoint(tablet-max) {
    display: none;
  }
}

.contact-mobile-img {
  position: relative;
  z-index: 1;
  border-radius: 20px;
  top: -280px;
  margin: 60px auto 0;
  min-width: 305px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.contact-container {
  @include breakpoint(desktop) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.form-container {
  @include breakpoint(desktop) {
    max-width: 700px;

    animation: fadeInUp;
    animation-duration: 1.4s;
  }
}

.blur-effect10 {
  position: relative;
  width: 270px;
  height: 275px;
  left: 55px;
  top: -12px;

  background: #52ffffe3;
  filter: blur(286px);
  @include breakpoint(desktop) {
    display: none;
  }
}

.contact-form {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @include breakpoint(desktop) {
    gap: 30px;
  }

  @include breakpoint(tablet-max) {
    top: -280px;
    gap: 15px;
  }

  textarea {
    @include breakpoint(desktop) {
      height: 100px;
      padding: 20px 25px;
      border-radius: 30px;
    }
    height: 80px;
    resize: none;
    border-radius: 20px;
    padding: 15px 20px;
  }

  input {
    @include breakpoint(desktop) {
      height: 60px;
      padding: 21px 25px;
    }

    border-radius: 70px;
    padding: 13px 20px;
  }
}

.form-input {
  background-color: inherit;
  border: 1px solid #f9f8fa;
  width: 100%;
  color: $secondaryTextColors;

  &::placeholder {
    @include breakpoint(desktop) {
      @include font($primary-font, 400, 20px, calc(20 / 20), $inputTextColors);
    }
    @include font($primary-font, 400, 14px, calc(14 / 14), $inputTextColors);
  }

  &:focus {
    outline: 0;
    outline-offset: 0;
    border: 1px solid $inputFocusTextColors;
    &::placeholder {
      color: $inputFocusTextColors;
    }
  }
}

// button form
.contact-btn-border1 {
  width: 171px;
  height: 35px;
  border-radius: 70px;

  @include breakpoint(desktop) {
    height: 45px;
  }

  background: linear-gradient(
    168deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0.6362920168067228) 64%,
    rgba(255, 255, 255, 1) 89%
  );
}

.contact-btn-border2 {
  position: absolute;
  z-index: 2;
  margin-top: 1px;
  width: 170px;
  height: 33px;
  border-radius: 70px;
  background-color: $primaryBgColors;
  @include breakpoint(desktop) {
    height: 43px;
  }
}

.contact-button {
  margin-top: -1px;
  position: absolute;
  z-index: 3;
  box-sizing: border-box;
  cursor: pointer;
  width: 170px;
  height: 35px;

  background: radial-gradient(
    163.09% 86.67% at 50% 48.89%,
    rgba(213, 233, 245, 0.2) 8.12%,
    #ffffff 100%
  );

  border: none;
  border-radius: 70px;
  @include font($primary-font, 400, 16px, calc(16 / 16), $secondaryTextColors);

  @include breakpoint(desktop) {
    height: 45px;
  }
}

.contact-button:focus {
  background: radial-gradient(
    735.73% 150% at 50% 120%,
    rgba(213, 233, 245, 0.2) 8.12%,
    #ffffff 100%
  );
}

.communication-list {
  position: relative;
  @include breakpoint(tablet-max) {
    top: -280px;
  }

  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  a {
    display: flex;
    align-items: center;
    gap: 20px;
    svg {
      stroke: $primaryTextColors;
    }
    &:hover p,
    &:focus p {
      color: #b8b4b4;
    }
    &:hover svg,
    &:focus svg {
      stroke: #b8b4b4;
    }
  }
  p {
    @include font($primary-font, 400, 20px, calc(20 / 20), $primaryTextColors);
  }
  .contact-icon-mail {
    margin-bottom: 4px;
  }
  @include breakpoint(desktop) {
    animation: fadeInUp;
    animation-duration: 1.4s;
  }
}

.validation-message,
.email-validation-message,
.message-validationMessage {
  @include breakpoint(desktop) {
    @include font($error-font, 400, 14px, calc(15 / 12), #ff1f1f);
   margin-top: 4px;
  }

  @include breakpoint(tablet-max) {
    @include font($error-font, 400, 12px, calc(15 / 12), #ff1f1f);
  }
  position: absolute;
  z-index: 1;
}
