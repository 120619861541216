// parcel serve src/index.html --port 1235

* {
  transition: 0.14s ease-in-out;
}

.header {
  @include breakpoint(desktop) {
    animation: fadeInDown;
    animation-duration: 1.1s;
    max-width: 1200px;
    margin: 0 auto;
    padding: 21px 15px 21px;
  }

  @include breakpoint(tablet-max) {
    padding: 20px 15px 0;
  }
}

.container-header {
  @include breakpoint(desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  width: 137px;
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.logo-mobile {
  position: relative;
  z-index: 2;
  @include breakpoint(desktop) {
    display: none;
  }
}

// Elements menu
.nav-menu {
  position: relative;
  display: flex;
  gap: 50px;
  margin-right: 28px;
  @include breakpoint(tablet-max) {
    display: none;
  }
  &__item-menu {
    @include font(
      $primary-font,
      400,
      16px,
      calc(16 / 16),
      $secondaryTextColors
    );
  }
}

.line-nav::after {
  content: '';
  height: 2px;
  width: 0%;
  display: block;
  transition: 0.4s ease-in-out;
}

.line-nav:hover::after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: $secondaryTextColors;
  display: block;
}

// icons-soc
.list-soc {
  position: relative;
  display: flex;
  gap: 15px;

  @include breakpoint(tablet-max) {
    display: none;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 38px;
    height: 38px;

    cursor: pointer;
    border-radius: 50%;
    background-color: #d5e9f5;
    &:hover .soc-icon,
    &:focus .soc-icon {
      stroke: #929292;
    }
  }
}

.soc-icon {
  fill: #d5e9f5;
  stroke: #031825;
}

//*    -------------------- MOBILE MENU --------------------
#menu-toggle span {
  display: block;
  background: $linesColors;
  border-radius: 2px;
  @include breakpoint(desktop) {
    display: none;
  }
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(desktop) {
    display: none;
  }
}

// backdrop-menu
.backdrop-menu {
  @include breakpoint(desktop) {
    display: none;
  }
  position: absolute;
  width: 100%;
  z-index: 9;
  overflow-x: hidden;
  top: 0px;
  left: 0px;
  transform: translateY(-109%);
  overflow: hidden;

  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  background-color: $primaryBgColors;

  transition: visibility 350ms linear, opacity 350ms linear,
    transform 350ms linear;

  &.is-open {
    width: 100%;
    transform: translateY(0);
    pointer-events: initial;
    visibility: visible;
    opacity: 1;
  }
}

.mobile-menu {
  padding: 20px 15px 0 15px;
  height: 900px;
}

#menu-toggle {
  position: absolute;
  z-index: 12;
  top: 20px;
  right: 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  #hamburger {
    position: absolute;
    height: 100%;
    width: 100%;
    span {
      width: 40px;
      height: 1px;
      position: relative;
      &:nth-child(1) {
        transition-delay: 0.5s;
      }
      &:nth-child(2) {
        transition-delay: 0.625s;
        margin-top: 19px;
      }
      &:nth-child(3) {
        transition-delay: 0.75s;
        margin-top: 19px;
        width: 32px;
        margin-left: 8px;
      }
    }
  }
  #cross {
    z-index: 18;
    height: 40px;
    width: 40px;

    span {
      background-color: #ffffff;
      &:nth-child(1) {
        height: 0%;
        width: 1px;
        position: absolute;
        top: -20%;
        left: 20px;
        transform: rotate(180deg);
        transition-delay: 0.25s;
      }
      &:nth-child(2) {
        width: 0%;
        height: 1px;
        position: absolute;
        left: -18%;
        top: 20px;
        transform: rotate(180deg);
        transition-delay: 0.25s;
      }
    }
  }
}

#menu-toggle.open {
  #hamburger {
    span {
      width: 0%;
      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: 0.125s;
      }
      &:nth-child(3) {
        transition-delay: 0.25s;
      }
    }
  }
  #cross {
    z-index: 18;
    span {
      &:nth-child(1) {
        height: 140%;
        transition-delay: 0.625s;
        transform: rotate(45deg);
      }
    }
    span {
      &:nth-child(2) {
        width: 140%;
        transition-delay: 0.625s;
        transform: rotate(45deg);
      }
    }
  }
}

// TODO Блюр (проблема с отображением на iphone 8)
.blur-effect1 {
  position: relative;
  z-index: 12;
  width: 237px;
  height: 165px;
  left: -28px;
  top: 80px;

  background: #52ffff;
  filter: blur(286px);

  @include breakpoint(desktop) {
    display: none;
  }
}

.blur-effect2 {
  position: relative;
  z-index: 12;
  width: 256px;
  height: 241px;
  left: 136px;
  top: 300px;

  background: #43a5ff;
  filter: blur(265px);

  @include breakpoint(desktop) {
    display: none;
  }
}

.nav-menu-mobile {
  position: relative;
  z-index: 13;
  top: -300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  &__item-menu {
    @include font(
      $primary-font,
      400,
      16px,
      calc(16 / 16),
      $secondaryTextColors
    );
  }
}

// icons-soc
.list-soc-mobile {
  position: relative;
  z-index: 13;
  top: -300px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 70px auto 44px auto;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 38px;
    height: 38px;

    border-radius: 50%;
    background-color: #d5e9f5;
  }
}

// human-image

.human-image {
  position: relative;
  z-index: 13;
  top: -300px;
  margin: 0 auto;
  max-width: 280px;
  height: 300px;
}
