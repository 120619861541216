html,
body {
  height: 100%;
  scroll-behavior: smooth;
  background-color: #051f30;
  
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}



img {
  display: block;
  max-width: 100%;
  height: auto;
  // @media screen and (max-width: 767.98px) {
  //   max-width: 100%;
  //   height: auto;
  // }

}

svg {
  fill: currentColor;
}

.list,
ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

section {
  padding: 0 15px;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.no-scroll {
  overflow: hidden;
}

