.my-values {
  @include breakpoint(desktop) {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 150px 15px;
    overflow-x: hidden;
  }

  @include breakpoint(tablet-max) {
    padding: 80px 15px 20px;
    height: 1650px;
  }

  h2 {
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(desktop) {
      margin-bottom: 80px;
      @include font(
        $secondary-font,
        400,
        50px,
        calc(62 / 50),
        $primaryTextColors
      );

      animation: slideInLeft;
      animation-duration: 1.8s;
    }

    @include breakpoint(tablet-max) {
      @include font(
        $secondary-font,
        400,
        40px,
        calc(50 / 40),
        $primaryTextColors
      );
    }

    span {
      color: $accentTextColors;
    }
  }

  img {
    @include breakpoint(tablet-max) {
      position: relative;
      z-index: 1;
      width: 100%;
      margin: 0 auto 80px;
    }
  }
}

.my-mission-desktop {
  &:hover {
    transform: scale(1.015);
  }
  @include breakpoint(tablet-max) {
    display: none;
  }
}
.my-mission-container {
  position: relative;
  display: flex;
  align-items: center;
}
.my-mission {
  
  top: -260px;
  min-width: 305px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.my-values-list {
  position: relative;
  z-index: 1;
  display: flex;

  @include breakpoint(desktop) {
    justify-content: center;
    gap: 120px;
    padding-bottom: 80px;
  }

  @include breakpoint(tablet-max) {
    flex-direction: column;
    padding-bottom: 60px;
    top: -260px;
    margin: 0 auto;
  }

  img {
    @include breakpoint(desktop) {
      width: 70px;
      margin: 0 auto 30px;
    }
    @include breakpoint(tablet-max) {
      width: 50px;
      margin: 40px auto 20px;
    }

    &:hover {
      transform: scale(1.02);
    }
  }

  h3 {
    text-align: center;
    margin-bottom: 20px;
    @include font($primary-font, 700, 20px, calc(24 / 20), $primaryTextColors);
  }

  p {
    text-align: center;
    @include font($primary-font, 400, 20px, calc(28 / 20), $primaryTextColors);
  }
  .text1 {
    margin: 0 auto;
    width: 265px;
  }

  .text2 {
    margin: 0 auto;
    width: 296px;
  }

  .text3 {
    margin: 0 auto;
    width: 265px;
  }
}

.my-values-text-desktop {
  padding: 170px 0 20px;

  ul {
    display: flex;
    flex-direction: column;

    li {
      position: relative;
      animation-duration: 1.6s;
      &:nth-child(2) {
        margin-left: auto;
      }

      &:nth-child(3) {
        margin-left: auto;
        margin-right: 150px;
      }

      &:nth-child(5) {
        margin-left: auto;
        margin-right: 134px;
      }

      &:nth-child(6) {
        margin-left: 80px;
      }

      &:nth-child(7) {
        margin-left: auto;
      }
    }
  }

  p {
    text-transform: uppercase;
    @include font($primary-font, 400, 54px, calc(89 / 54), $primaryTextColors);
  }
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.my-values-text {
  position: relative;
  top: -546px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 345px;
  @include font($primary-font, 300, 20px, calc(40 / 20), $primaryTextColors);

  @include breakpoint(desktop) {
    display: none;
  }
}

.my-values-text-author {
  position: relative;
  display: flex;
  justify-content: flex-end;
  @include breakpoint(desktop) {
    @include font(
      $secondary-font,
      400,
      20px,
      calc(24 / 20),
      $primaryTextColors
    );
    animation: fadeInRight;
    animation-duration: 1.4s;
  }
  @include breakpoint(tablet-max) {
    top: -546px;
    text-align: center;
    @include font(
      $secondary-font,
      400,
      16px,
      calc(24 / 16),
      $primaryTextColors
    );
  }
}

// BLUR
.blur-effect8 {
  position: relative;
  top: 30px;
  width: 316px;
  height: 252px;
  left: 0px;
  @include breakpoint(desktop) {
    display: none;
  }
  background: #52ffff;
  filter: blur(286px);
}

.blur-effect9 {
  position: relative;
  width: 219px;
  height: 283px;
  left: 100px;
  top: -404px;
  @include breakpoint(desktop) {
    display: none;
  }
  background: #43a4ff;
  filter: blur(265px);
}
