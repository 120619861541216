#container {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  @include breakpoint(tablet-max) {
    display: none;
  }
}

.vector-lines1 {
  position: absolute;
  top: -300px;
  right: -425px;
}

.vector-lines2 {
  position: absolute;
  top: 2360px;
  left: -440px;
}

.vector-lines3 {
  position: absolute;
  top: 2936px;
  right: -150px;
}

.vector-lines4 {
  position: absolute;
  top: 4920px;
  left: -120px;
}

.ellipse-desktop-1 {
  position: absolute;
  width: 379px;
  height: 266px;
  left: -116px;
  top: 116px;

  background: #52ffffc0;
  filter: blur(206px);
}

.ellipse-desktop-2 {
  position: absolute;
  width: 353px;
  height: 365px;
  left: 985px;
  top: 264px;

  background: #43a4ffd0;
  filter: blur(215px);
}

.ellipse-desktop-3 {
  position: absolute;
  width: 353px;
  height: 365px;
  left: -96px;
  top: 1018px;

  background: #43a4ffab;
  filter: blur(200px);
}

.ellipse-desktop-4 {
  position: absolute;
  width: 379px;
  height: 266px;
  left: 956px;
  top: 1734px;

  background: #52ffffb9;
  filter: blur(286px);
}

.ellipse-desktop-5 {
  position: absolute;
  width: 353px;
  height: 365px;
  left: 1204px;
  top: 2000px;

  background: #43a4ffc0;
  filter: blur(265px);
}

.ellipse-desktop-6 {
  position: absolute;
  width: 379px;
  height: 266px;
  left: 306px;
  top: 2501px;

  background: rgba(82, 255, 255, 0.7);
  filter: blur(286px);
}

.ellipse-desktop-7 {
  position: absolute;
  width: 385px;
  height: 316px;
  left: 726px;
  top: 3581px;

  background: #52ffffb4;
  filter: blur(286px);
}

.ellipse-desktop-8 {
  position: absolute;
  width: 353px;
  height: 365px;
  left: 1134px;
  top: 4216px;

  background: #43a4ffb6;
  filter: blur(265px);
}

.ellipse-desktop-9 {
  position: absolute;
  width: 379px;
  height: 266px;
  left: -24px;
  top: 4824px;

  background: #52ffffb2;
  filter: blur(286px);
}

.ellipse-desktop-10 {
  position: absolute;
  width: 430px;
  height: 395px;
  left: 785px;
  top: 5324px;

  background: rgba(67, 165, 255, 0.6);
  filter: blur(265px);
}

// .ellipse-desktop-11 {
//   position: absolute;
//   width: 379px;
//   height: 266px;

//   background: rgba(82, 255, 255, 0.7);
//   filter: blur(286px);
// }
