.ticker-container {
    margin: 0 auto 45px;
    max-width: 1440px;
    overflow: hidden;
  
    @include breakpoint(tablet-max) {
      display: none;
    }
  
    div {
      display: flex;
      width: 200%;
      animation: marquee-infinite 12s linear infinite;
    }
  
    span {
      width: 100%;
      display: flex;
      gap: 50px;
      justify-content: space-around;
    }
  
    p {
      
      width: 20%;
      height: 100%;
      @include font(
        $secondary-font,
        400,
        55px,
        calc(68 / 55),
        $transparentTextColors
      );
    }
  }
  .ticker-text {
    gap: 50px;
  }
  
  @keyframes marquee-infinite {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-101%); //-124%
    }
  }